import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import Card from "../components/Card";
import Lottie from "react-lottie";
import animationData from "../assets/404.json";
import { Center, Flex, Heading, Text, chakra } from "@chakra-ui/react";

const NotFoundPage = () => {
  return (
    <Layout>
      <Card>
        <Flex
          mt={10}
          maxW="100%"
          w={500}
          mx="auto"
          h="fullWidth"
          direction="column"
          align="center"
          justify="center"
          textAlign="center"
        >
          <Lottie
            options={{ loop: true, autoplay: true, animationData }}
            height={400}
            width={400}
          ></Lottie>
          <Text fontSize="lg">
            It looks like there is a problem with this page and we are working
            to resolve it.
          </Text>
        </Flex>
      </Card>
    </Layout>
  );
};

export default NotFoundPage;
